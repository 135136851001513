/* You can add global styles to this file, and also import other style files */

/*Global colors*/

:root {
  --primary-blue: #2c87de;
  --text-light: #f5f7f8;
  --text-dark: #0f1c29;
  --grid-light1: #e8ecee;
  --grid-light2: #e1e4e6;
  --grid-light-hover: #f5f7f8;
}
/* AVS Color Palette */
:root {
  --asv-font-light1: #ffffff;
  --asv-font-light2: #f5f7f8;
  --asv-font-dark1: #0f1c29;
  --asv-font-dark2: #1b2923;

  --asv-blue-grey-100: #0f1c29;
  --asv-blue-grey-200: #1b2939;
  --asv-blue-grey-300: #1e2d3d;
  --asv-blue-grey-400: #2d3e50;
  --asv-blue-grey-500: #40546b;
  --asv-blue-grey-600: #526b84;
  --asv-blue-grey-700: #6f849e;
  --asv-blue-grey-800: #e1e4e6;

  --asv-blue-300: #236fb8;
  --asv-blue-400: #2c87de;
  --asv-blue-500: #339bff;
  --asv-blue-600: #00aeef;

  --asv-dark-blue: #003595;

  --asv-stone-grey: #c4d0e5;
  --asv-border-grey: #6F849B;

  --asv-advisory: #339bff;
  --asv-warning: #f3ea32;
  --asv-fault: #d2232a;
}

.mat-tab-label-container {
  background-color: #fafafa;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto;
}

/*=====LOGIN PAGE STYLES============*/
.c-login-background {
  background: rgb(20, 52, 137);
  background: linear-gradient(180deg, rgba(20, 52, 137, 1) 35%, rgba(0, 163, 255, 1) 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ==========GLOBAL PAGE ELEMENTS STYLES===========*/
.c-page-heading-text {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: x-large;
  color: #143489;
}

.c-main-container {
  flex: 1;
}

.c-main-container.c-tab-container {
  height: calc(100vh - 158px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.c-flex-wrapper {
  display: flex;
  min-height: calc(100vh - 140px);
  flex-direction: column;
}

.c-flex-wrapper-log-parser {
  display: flex;
  min-height: calc(100vh - 160px);
  flex-direction: column;
}

.c-loading-spinner {
  left: 50%;
  margin-top: 25vh;
  margin-left: -50px;
  flex: 1;
}

.c-page-heading-text-element-list {
  margin-top: 15px;
  margin-bottom: -1px;
}

/* ==========GLOBAL TABLE STYLES===========*/
table {
  width: 100%;
}

.c-table-header-style {
  font-weight: bold;
  font-size: small;
  background-color: #526b84;
  width: 200px;
  color: white;
}

.mat-sort-header-arrow {
  color: white;
}

.mat-row:nth-child(even) {
  background-color: #e8ecee;
}

.mat-row:nth-child(even).hovered {
  background-color: #f5f7f8;
}

.mat-row:nth-child(odd) {
  background-color: #e1e4e6;
}

.mat-row:nth-child(odd).hovered {
  background-color: #f5f7f8;
}

.mat-row.highlighted {
  background: #999;
}

.c-table-info {
  text-align: center;
  padding: 200px 0px;
  font-size: x-large;
}

.c-table-info-error {
  text-align: center;
  padding: 200px 0px;
  font-size: x-large;
  color: red;
}

/* ==========GLOBAL BUTTON STYLES===========*/
.c-primary-button-active {
  background-color: #2c87de;
  color: white;
}

.c-secondary-button-active:hover {
  background-color: #f5f7f8;
}

.c-secondary-button-active {
  background-color: #e1e4e6;
  color: black;
  border: 1px solid #339bff !important;
}

.c-download-button-active {
  background-color: Green;
  color: white;
  margin-left: 10px;
}

/* ==========GLOBAL PAGINATOR STYLES===========*/
.c-paginator {
  background-color: #526b84;
  color: white;
}

.mat-select-value {
  color: white;
}

.mat-select-arrow {
  color: white;
}

/*===========BUTTON LOADING SPINNER=============*/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #003595;
}

/* MAT TAB customization*/
.mat-tab-group {
  background-color: #e1e4e6;
  margin: 0px 40px 0px 40px;
  min-height: 78vh;
}

.mat-tab-label.mat-tab-label-active {
  min-width: 25px !important;
  background-color: #e1e4e6;
  color: black;
  font-weight: 700;
  border-right: 1px solid;
  border-color: #3f51b5;
  opacity: 1;
}

/* Styles for the active tab label */
.mat-tab-label {
  min-width: 25px !important;
  background-color: #2c87de;
  padding: 5px;
  color: white;
  font-weight: 700;
  border-right: 1px solid;
  border-color: rgba(0, 0, 0);
  opacity: 1;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e1e4e6;
}

/* ==================== Select drop down customization ===================*/
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0px;
}

.mat-select-value {
  color: black;
}

.mat-select-arrow {
  color: black;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0);
}

/* DATE PICKER CUSTOMIZATION */
.md-drppicker .btn {
  background-color: #f5f7f8 !important;
  margin-right: 10px;
  color: rgb(0, 0, 0) !important;
}

.mat-tab-header {
  background-color: white;
}

/* DIALOG PANEL CUSTOMIZATION */

.dialog-panel {
  border-radius: 2%;
  text-align: center;
}

.mat-dialog-container {
  padding: 0px !important;
}

/* Check box CUSTOMIZATION */
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #1f45cc;
}

/* Error notice config */
.c-mini-error-notice {
  margin-top: 15px;
  background-color: #bd362f;
  color: white;
  text-align: center;
  font-size: small;
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

/* Slide toggle config */
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #003595;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(64, 93, 255, 0.109);
}

/* angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 65px;
  right: 5px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.mat-button-toggle-appearance-standard {
  background: #f5f7f8;
}

.input-error-wrapper {
  min-height: 24px;
  color: red;
}

.input-error-wrapper div {
  text-align: center;
  padding: 3px 0;
}

/* New form fields */

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  border-top: none;
  padding: 0.8em 0 0.7em 0;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex .mat-form-field-outline {
  background-color: #e8ecee;
  margin-top: 0;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: 0 !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0.3em !important;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-prefix {
  top: 0.2em !important;
}
::ng-deep .mat-form-field-appearance-outline .mat-form-field-prefix .mat-icon {
  margin-right: 0.3em;
  width: 20px;
  height: 20px;
  font-size: 20px;
}

/* input outline color */
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #b1bdc8 !important;
  opacity: 0.6 !important;
}

/* mat-input focused color */
::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #b1bdc8 !important;
}

/* mat-input error outline color */
::ng-deep .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #b1bdc8 !important;
  opacity: 0.6 !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}

/* Alcon style expansion panel  */

::ng-deep .asc-expansion-panel {
  border-radius: 0 !important;
  background-color: red;
}

::ng-deep .mat-expansion-panel.asc-expansion-panel.mat-expanded {
  background-color: #ffffff !important;
  border-color: #3abff0 !important;
}

/*Toggle group*/
::ng-deep .mat-button-toggle-group.asc-toggle-group .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  line-height: 32px !important;
  background-color: red;
}

